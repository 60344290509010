<template>
  <div class="tickets-detail">
    <el-breadcrumb separator="|">
      <el-breadcrumb-item :to="{ name: 'tickets-management' }">票券管理</el-breadcrumb-item>
      <el-breadcrumb-item>票券详情</el-breadcrumb-item>
    </el-breadcrumb>
    <ym-table-list :data=data
                   :columns="columns"
                   enableOperatorSlot
                   operatorWidth="100px"
                   :pageLayout="pageLayout"
                   :total="total"
                   :pageNumber="page"
                   @handlePageJump="handlePageJump">
      <!-- 顶部记账单位详细信息 -->
      <template #topInfo>
        <div class="ticket-detail">
          <div class="ticket-info">
            <div class="ticket-name">
              <span class="title">票券名称：</span>
              <span class="info">{{infoDetail.ticketName}}</span>
            </div>
            <div class="ticket-time">
              <div class="expiry">
                <span class="title">有效期：</span>
                <span class="info">{{infoDetail.ticketBeginTime | timeFilter('YYYY.MM.DD')}} - {{infoDetail.ticketEndTime | timeFilter('YYYY.MM.DD')}}</span>
              </div>
              <div class="createTime">
                <span class="title">创建时间：</span>
                <span class="info">{{infoDetail.ticketCreateTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}</span>
              </div>
            </div>
          </div>
          <div class="ticket-number">
            <div class="number-list">
              <span class="info">{{infoDetail.quantity}}</span>
              <span class="title">总数</span>
            </div>
            <div class="number-list">
              <span class="info">{{infoDetail.useQuantity}}</span>
              <span class="title">已发放</span>
            </div>
            <div class="number-list">
              <span class="info">{{infoDetail.confirmQuantity}}</span>
              <span class="title">已核销</span>
            </div>
            <div class="number-list">
              <span class="info">{{infoDetail.remainderQuantity}}</span>
              <span class="title">剩余数量</span>
            </div>
          </div>
        </div>
      </template>

      <template #topControl>
        <div class="top-control clearfix">
          <div class="dropdownlist-tag"
               v-if="showSelectedTag">
            <template v-for="status in selectedStatus">
              <el-tag closable
                      @close="clearSelectedStatus"
                      disable-transitions
                      :key="status">{{status}}</el-tag>
            </template>
          </div>
          <!-- 顶部右侧刷新 -->
          <div class="top-control-refresh">
            <el-button icon="el-icon-refresh"
                       @click="handleRefresh">
            </el-button>
          </div>
          <!-- 顶部右侧搜索 -->
          <div class="top-control-search">
            <el-input v-model="search"
                      placeholder="搜索联系电话"
                      prefix-icon="el-icon-search"
                      clearable
                      @clear="handleSearch"
                      @keyup.enter.native="handleSearch">
            </el-input>
          </div>
        </div>
        <div class='dropdownlist'>
          <el-dropdown trigger="click"
                       @command="selectStatus">
            <el-button>状态</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="all">全部</el-dropdown-item>
              <el-dropdown-item command="notUsed">未核销</el-dropdown-item>
              <el-dropdown-item command="used">已核销</el-dropdown-item>
              <el-dropdown-item command="expired">已过期</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>

      <template #status="{row}">
        <template v-if="row.status === '已过期'">
          <div :style="{color: '#fe8b56'}">{{row.status}}</div>
        </template>
        <template v-else-if="row.status === '已兑换'">
          <div>{{ '已核销' || row.status}}</div>
        </template>
        <template v-else-if="row.status === '未兑换'">
          <div :style="{color: '#4674e6'}">{{'未核销' ||row.status}}</div>
        </template>
      </template>

      <template #giveOutTime="{row}">
        <template v-if="row.giveOutTime">{{row.giveOutTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}</template>
        <template v-else>-</template>
      </template>

      <template #exchangeTime="{row}">
        <template v-if="row.exchangeTime">{{row.exchangeTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}</template>
        <template v-else>-</template>
      </template>

      <template #operatorCol="{row}">
        <el-button class="operator-btn ym-icon-liulan"
                   @click.stop="showTicketDetail(row)">流转记录</el-button>
      </template>

    </ym-table-list>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'

export default {
  components: {
    YmTableList
  },
  data() {
    return {
      data: [],
      columns: [{
        label: '兑换码',
        prop: 'code',
        align: 'center'
      },
      {
        label: '联系电话',
        prop: 'phone',
        align: 'center'
      },
      {
        label: '状态',
        prop: 'status',
        align: 'center',
        enableColSlot: true
      },
      {
        label: '获取时间',
        prop: 'giveOutTime',
        align: 'center',
        enableColSlot: true
      },
      {
        label: '核销时间',
        prop: 'exchangeTime',
        align: 'center',
        enableColSlot: true
      }],
      pageLayout: 'prev, pager, next, jumper',
      total: 10, // 数据总数
      search: '', // 搜索内容
      row: {}, // 当前行信息
      selected: [],
      selectedStatus: null, // 已选择的状态
      showSelectedTag: false, // 是否存在已选择的状态,
      infoDetail: {},
      page: 1,
      size: 10,
      ticketId: ''
    }
  },
  mounted() {
    // 加载对应记账单位的详情 data
    this.ticketId = this.$route.query.ticketId
    console.log(this.ticketId)
    this.getList()
    // this.data =
  },
  watch: {
    selectedStatus() {
      this.showSelectedTag = this.hasSelected()
    }
  },
  methods: {
    // 对搜索框及状态选择标签进行搜索
    handleSearch() {
      this.page = 1
      this.getList()
    },
    // 是否存在筛选条件
    hasSelected() {
      if (this.selectedStatus) {
        return true
      } else {
        return false
      }
    },
    // 清楚筛选条件
    clearSelectedStatus() {
      this.selectedStatus = null
      this.handleSearch()
    },
    // 筛选状态条件
    selectStatus(command) {
      switch (command) {
        case 'all':
          this.selectedStatus = null
          this.selected = []

          break
        case 'used':
          this.selectedStatus = []
          this.selectedStatus.push('已核销')
          this.selected = []
          this.selected.push('已兑换')

          break
        case 'expired':
          this.selectedStatus = []
          this.selectedStatus.push('已过期')
          this.selected = []
          this.selected.push('已过期')

          break
        case 'notUsed':
          this.selectedStatus = []
          this.selectedStatus.push('未核销')
          this.selected = []
          this.selected.push('未兑换')
          break
        default:
          this.selectedStatus = null
          this.selected = []
          break
      }
      this.handleSearch()
    },
    // 清空搜索条件(包括状态选择和搜索文本)
    handleRefresh() {
      this.selectedStatus = null
      this.search = ''
      this.handleSearch()
    },
    // 处理页码跳转
    handlePageJump(page, pageSize) {
      this.page = page
      this.getList()
    },
    getList() {
      const list = this.selected.map(item => {
        if (item === '已核销') {
          item = '已兑换'
        }
        if (item === '未核销') {
          item = '未兑换'
        }
        return item
      })

      this.$http.ticket.getTicketDetailList({
        size: this.size,
        page: this.page,
        phone: this.search,
        statusList: list,
        ticketId: this.ticketId
      }).then(res => {
        if (res.data.code === 0) {
          this.data = res.data.data.records
          this.infoDetail = res.data.data
          this.total = res.data.data.total
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    showTicketDetail(row) {
      this.$router.push({
        name: 'ticket-transfer-record',
        query: {
          ticketId: this.ticketId,
          id: row.id,
          code: row.code,
          infoDetail: encodeURIComponent(JSON.stringify({ ticketName: this.infoDetail.ticketName, ticketEndTime: this.infoDetail.ticketEndTime, ticketTypeName: this.infoDetail.ticketTypeName, sendUserName: this.infoDetail.sendUserName }))
        }
      })
    }
  }

}
</script>

<style lang="scss">
.tickets-detail {
  .el-breadcrumb {
    margin-bottom: 31px;
    &__item {
      line-height: 20px;
      .is-link {
        color: #b3b4ba;
        &:hover {
          color: $--color-primary;
        }
      }
      &[aria-current="page"] {
        font-size: 20px;
        .el-breadcrumb__inner {
          font-weight: 600;
          color: $--color-text-primary;
        }
      }
    }
    &__separator {
      font-size: 16px;
    }
  }
  .ticket-detail {
    padding: 24px 0 26px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .ticket-info {
      font-size: 16px;
      .title {
        display: block;
        margin-bottom: 10px;
        color: #b3b4ba;
      }
      .info {
        display: block;
        color: $--color-text-primary;
        font-weight: 600;
      }
      .ticket-time {
        margin-top: 27px;
        .expiry {
          float: left;
        }
        .createTime {
          float: left;
          margin-left: 90px;
        }
      }
    }
    .ticket-number {
      margin-top: 16px;
      .number-list {
        float: left;
        text-align: center;
        margin-top: 20px;
        margin-right: 115px;
        .title {
          display: block;
          font-size: 16px;
          color: #b3b4ba;
        }
        .info {
          display: block;
          margin-bottom: 20px;
          font-size: 42px;
          font-weight: 700;
          color: $--color-text-primary;
        }
      }
    }
  }
  .top-control {
    margin-top: 16px;
    margin-bottom: 16px;
    .dropdownlist-tag {
      float: left;
      margin-left: 32px;
      .el-tag {
        position: relative;
        width: 110px;
        height: 40px;
        font-size: 14px;
        line-height: 41px;
        background-color: $--color-primary-light-9;
        border: none;
        border-radius: 6px;
        color: $--color-text-primary;
        padding-left: 20px;
        padding-right: 11px;
        cursor: default;
        .el-icon-close {
          position: absolute;
          right: 11px;
          top: 50%;
          margin-top: -8px;
          color: $--color-text-primary;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
    &-search {
      float: right;
      width: 240px;
      margin-right: 24px;
      .el-input {
        border-radius: 6px;
      }
    }
    &-refresh {
      float: right;
      margin-right: 24px;
      .el-button {
        padding: 8px 11px;
        .el-icon-refresh::before {
          font-size: 20px;
        }
      }
    }
    &-add {
      float: right;
      margin-right: 30px;
      font-size: $--font-size-medium;
      font-weight: $--font-weight-primary;
    }
  }
  .dropdownlist {
    padding-top: 19px;
    padding-bottom: 19px;
    border-top: 1px solid #e5e8f3;
    .el-button {
      width: 90px;
      height: 41px;
      margin-left: 32px;
    }
  }
}
</style>
